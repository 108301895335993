var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('header-main'),_c('header',{staticClass:"header content"},[_c('div',{staticClass:"header-video",attrs:{"itemscope":"","itemtype":"https://schema.org/VideoGallery"}},[_c('video',{attrs:{"itemprop":"video","src":require("../../public/statics/video/projects.mp4"),"autoplay":"","loop":"","muted":""},domProps:{"muted":true}})]),_c('div',{staticClass:"header-overlay"}),_c('div',{staticClass:"header-content"},[_c('div',{staticClass:"projects-hero__box"},[_c('h1',{staticClass:"projects-hero__h1"},[_vm._v("Proyectos Terminados")]),_c('p',{staticClass:"projects-hero__p"},[_vm._v(" Proyectos entregados a satisfaccion y con garantia, analizamos las necesidades del cliente y decidimos cuales son las mejores opciones en arquitectura para contruir su proyecto, aqui algunas de las tecnologías que utilizamos. ")]),_c('div',{staticClass:"projects-hero__projects"},[_c('v-tooltip',{staticClass:"projects-hero__tooltip",attrs:{"bottom":"","color":"purple"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('img',_vm._g(_vm._b({attrs:{"src":"statics/img/arquitectura/flutter.png","alt":"Logo de Flutter"}},'img',attrs,false),on))]}}])},[_c('span',[_vm._v("Flutter")])]),_c('v-tooltip',{staticClass:"projects-hero__tooltip",attrs:{"bottom":"","color":"purple"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('img',_vm._g(_vm._b({attrs:{"src":"statics/img/arquitectura/materialui.png","alt":"Logo de Material UI"}},'img',attrs,false),on))]}}])},[_c('span',[_vm._v("Material UI")])]),_c('v-tooltip',{staticClass:"projects-hero__tooltip",attrs:{"bottom":"","color":"purple"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('img',_vm._g(_vm._b({attrs:{"src":"statics/img/arquitectura/mongodb.png","alt":"Logo de Mongo DB"}},'img',attrs,false),on))]}}])},[_c('span',[_vm._v("Mongo DB")])]),_c('v-tooltip',{staticClass:"projects-hero__tooltip",attrs:{"bottom":"","color":"purple"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('img',_vm._g(_vm._b({attrs:{"src":"statics/img/arquitectura/mysql.png","alt":"Logo de Mysql"}},'img',attrs,false),on))]}}])},[_c('span',[_vm._v("Mysql")])]),_c('v-tooltip',{staticClass:"projects-hero__tooltip",attrs:{"bottom":"","color":"purple"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('img',_vm._g(_vm._b({attrs:{"src":"statics/img/arquitectura/php.png","alt":"Logo de PHP"}},'img',attrs,false),on))]}}])},[_c('span',[_vm._v("PHP")])]),_c('v-tooltip',{staticClass:"projects-hero__tooltip",attrs:{"bottom":"","color":"purple"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('img',_vm._g(_vm._b({attrs:{"src":"statics/img/arquitectura/reactjs.png","alt":"Logo de React JS"}},'img',attrs,false),on))]}}])},[_c('span',[_vm._v("React js")])]),_c('v-tooltip',{staticClass:"projects-hero__tooltip",attrs:{"bottom":"","color":"purple"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('img',_vm._g(_vm._b({attrs:{"src":"statics/img/arquitectura/vuejs.png","alt":"Logo de Vue JS"}},'img',attrs,false),on))]}}])},[_c('span',[_vm._v("Vue.js")])]),_c('v-tooltip',{staticClass:"projects-hero__tooltip",attrs:{"bottom":"","color":"purple"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('img',_vm._g(_vm._b({attrs:{"src":"statics/img/arquitectura/css.png","alt":"Logo de CSS"}},'img',attrs,false),on))]}}])},[_c('span',[_vm._v("CSS")])]),_c('v-tooltip',{staticClass:"projects-hero__tooltip",attrs:{"bottom":"","color":"purple"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('img',_vm._g(_vm._b({attrs:{"src":"statics/img/arquitectura/html.png","alt":"Logo de HTML"}},'img',attrs,false),on))]}}])},[_c('span',[_vm._v("HTML")])])],1)])])]),_c('portfolio',{staticClass:"portfolio__component"}),_c('our-clients'),_c('footer-main')],1)}
var staticRenderFns = []

export { render, staticRenderFns }