<template>
  <div>
    <header-main></header-main>
    <header class="header content">
      <div
        class="header-video"
        itemscope
        itemtype="https://schema.org/VideoGallery"
      >
        <video
          itemprop="video"
          src="../../public/statics/video/projects.mp4"
          autoplay
          loop
          muted
        ></video>
      </div>
      <div class="header-overlay"></div>
      <div class="header-content">
        <div class="projects-hero__box">
          <h1 class="projects-hero__h1">Proyectos Terminados</h1>
          <!-- <h2 class="projects-hero__h2">#CalidadAppears</h2> -->
          <p class="projects-hero__p">
            Proyectos entregados a satisfaccion y con garantia, analizamos las
            necesidades del cliente y decidimos cuales son las mejores opciones
            en arquitectura para contruir su proyecto, aqui algunas de las
            tecnologías que utilizamos.
          </p>
          <div class="projects-hero__projects">
            <v-tooltip bottom color="purple" class="projects-hero__tooltip">
              <template v-slot:activator="{ on, attrs }">
                <img
                  src="statics/img/arquitectura/flutter.png"
                  alt="Logo de Flutter"
                  v-bind="attrs"
                  v-on="on"
                />
              </template>
              <span>Flutter</span>
            </v-tooltip>
            <v-tooltip bottom color="purple" class="projects-hero__tooltip">
              <template v-slot:activator="{ on, attrs }">
                <img
                  src="statics/img/arquitectura/materialui.png"
                  alt="Logo de Material UI"
                  v-bind="attrs"
                  v-on="on"
                />
              </template>
              <span>Material UI</span>
            </v-tooltip>
            <v-tooltip bottom color="purple" class="projects-hero__tooltip">
              <template v-slot:activator="{ on, attrs }">
                <img
                  src="statics/img/arquitectura/mongodb.png"
                  alt="Logo de Mongo DB"
                  v-bind="attrs"
                  v-on="on"
                />
              </template>
              <span>Mongo DB</span>
            </v-tooltip>
            <v-tooltip bottom color="purple" class="projects-hero__tooltip">
              <template v-slot:activator="{ on, attrs }">
                <img
                  src="statics/img/arquitectura/mysql.png"
                  alt="Logo de Mysql"
                  v-bind="attrs"
                  v-on="on"
                />
              </template>
              <span>Mysql</span>
            </v-tooltip>
            <v-tooltip bottom color="purple" class="projects-hero__tooltip">
              <template v-slot:activator="{ on, attrs }">
                <img
                  src="statics/img/arquitectura/php.png"
                  alt="Logo de PHP"
                  v-bind="attrs"
                  v-on="on"
                />
              </template>
              <span>PHP</span>
            </v-tooltip>
            <v-tooltip bottom color="purple" class="projects-hero__tooltip">
              <template v-slot:activator="{ on, attrs }">
                <img
                  src="statics/img/arquitectura/reactjs.png"
                  alt="Logo de React JS"
                  v-bind="attrs"
                  v-on="on"
                />
              </template>
              <span>React js</span>
            </v-tooltip>
            <v-tooltip bottom color="purple" class="projects-hero__tooltip">
              <template v-slot:activator="{ on, attrs }">
                <img
                  src="statics/img/arquitectura/vuejs.png"
                  alt="Logo de Vue JS"
                  v-bind="attrs"
                  v-on="on"
                />
              </template>
              <span>Vue.js</span>
            </v-tooltip>
            <v-tooltip bottom color="purple" class="projects-hero__tooltip">
              <template v-slot:activator="{ on, attrs }">
                <img
                  src="statics/img/arquitectura/css.png"
                  alt="Logo de CSS"
                  v-bind="attrs"
                  v-on="on"
                />
              </template>
              <span>CSS</span>
            </v-tooltip>
            <v-tooltip bottom color="purple" class="projects-hero__tooltip">
              <template v-slot:activator="{ on, attrs }">
                <img
                  src="statics/img/arquitectura/html.png"
                  alt="Logo de HTML"
                  v-bind="attrs"
                  v-on="on"
                />
              </template>
              <span>HTML</span>
            </v-tooltip>
          </div>
        </div>
      </div>
    </header>
    <portfolio class="portfolio__component"></portfolio>
    <our-clients></our-clients>
    <footer-main></footer-main>
  </div>
</template>

<script>
import HeaderMain from "@/components/Header.vue";
import Portfolio from "@/components/Portfolio.vue";
import OurClients from "@/components/OurClients.vue";
import FooterMain from "@/components/Footer.vue";

export default {
  name: "PageProjects",
  components: {
    HeaderMain,
    Portfolio,
    OurClients,
    FooterMain,
  },
  data() {
    return {
      swiperOptionAbout: {
        pagination: {
          el: ".swiper-pagination-about-us",
          clickable: true,
        },
      },
    };
  },
  metaInfo() {
    return {
      title: "Appears |",
      titleTemplate: "%s Agencia Digital | Proyectos Terminados.",
      meta: [
        {
          name: "description",
          content:
            "Somos un equipo preparado para desarrollar tu idea con el objetivo de llevarla a un nivel superior, descubrirás el impacto de la tecnología en esta era digital.",
        },
        {
          name: "keywords",
          content:
            "Appears, paginas web, paginas web villavicencio, sitio web, seo, sem, aplicaciones a la medida, software, google ads, facebook ads, publicidad en google, publicidad en facebook, publicidad en instagram, web, website, diseño web, desarrollo web",
        },
        {
          name: "author",
          content: "Appears",
        },
        {
          name: "robots",
          content: "Index, Follow",
        },
        {
          rel: "canonical",
          href: "https://appears.com.co/",
        },
        // Tags twitter
        {
          name: "twitter:card",
          content: "summary",
        },
        {
          name: "twitter:site",
          content: "@appears",
        },
        {
          name: "twitter:creator",
          content: "@appears",
        },
        {
          name: "twitter:title",
          content:
            "Appears | Agencia Digital | Estamos reestructurando nuestro sitio web.",
        },
        {
          name: "twitter:description",
          content:
            "Somos un equipo preparado para desarrollar tu idea con el objetivo de llevarla a un nivel superior, descubrirás el impacto de la tecnología en esta era digital.",
        },
        {
          name: "twitter:image",
          content: "https://appears.com.co/statics/img/img-str.png",
        },
        // Tags facebook
        {
          property: "og:locale",
          content: "es_CO",
        },
        {
          property: "og:site_name",
          content: "Appears",
        },
        {
          property: "og:url",
          content: "https://appears.com.co",
        },
        {
          property: "og:type",
          content: "website",
        },
        {
          property: "og:title",
          content:
            "Appears | Agencia Digital | Estamos reestructurando nuestro sitio web.",
        },
        {
          property: "og:description",
          content:
            "Somos un equipo preparado para desarrollar tu idea con el objetivo de llevarla a un nivel superior, descubrirás el impacto de la tecnología en esta era digital.",
        },
        {
          property: "og:image",
          content: "https://appears.com.co/statics/img/img-str.png",
        },
        {
          property: "og:image:secure_url",
          content: "https://appears.com.co/statics/img/img-str.png",
        },
        {
          property: "og:image:width",
          content: "705",
        },
        {
          property: "og:image:height",
          content: "427",
        },
      ],
    };
  },
};
</script>

<style scoped>
.portfolio__component {
  position: relative;
  z-index: 10;
  margin-top: -80px;
}

.header {
  height: 70vh;
  display: flex;
  align-items: center;
  color: #fff;
}

.content {
  max-width: 70rem;
  padding-left: 1rem;
  padding-right: 1rem;
  margin: auto;
  text-align: center;
}

.header-video {
  position: absolute;
  top: 100px;
  left: 0;
  width: 100%;
  height: 84vh;
  overflow: hidden;
}

.header-video video {
  top: 0;
  left: 0;
  width: 100% !important;
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
}

.header-overlay {
  height: 85vh;
  width: 100vw;
  position: absolute;
  top: 100px;
  left: 0;
  background: var(--white-secondary);
  z-index: 1;
  opacity: 0.85;
}

.header-content {
  z-index: 2;
}

.header-content p {
  font-size: 20px;
  display: block;
  padding-bottom: 2rem;
}

.btn {
  background: #c44569;
  color: #fff;
  font-size: 1.2rem;
  padding: 1rem 2rem;
  text-decoration: none;
}
.projects-hero__h1 {
  color: var(--primary-opt);
  font-size: 50px;
  font-weight: 800;
  line-height: 0;
}
.projects-hero__h2 {
  color: var(--black);
  font-size: 30px;
  font-weight: 800;
  line-height: 1;
}
.projects-hero__h2 span {
  color: var(--positive);
  font-size: 35px;
  font-weight: 800;
  line-height: 1;
}
.projects-hero__p {
  margin-top: 20px;
  margin-right: 20px;
  color: var(--black);
  font-size: 20px;
  font-weight: normal;
}

.projects-hero__projects img {
  height: 70px;
  margin-right: 5px;
  background: var(--white);
  padding: 10px;
  border: 2.5px solid var(--primary);
  border-radius: 15px;
}
.projects-hero__tooltip {
  background: var(--primary) !important;
}
@media screen and (max-width: 1199px) {
}
@media screen and (max-width: 991px) {
}
@media screen and (max-width: 850px) {
}
@media screen and (max-width: 767px) {
  .projects__component {
    margin-top: -90px;
  }
  .projects-hero__h1 {
    font-size: 35px;
  }
  .projects-hero__projects img {
    height: 50px;
  }
}
@media screen and (max-width: 585px) {
  /* .projects__component {
    margin-top: -90px;
  } */
  .projects-hero__projects img {
    height: 70px;
  }

  .header-content p {
    font-size: 17px;
    padding-bottom: 0;
  }
}
@media screen and (max-width: 480px) {
  .header-content {
    z-index: 20;
  }
  .projects-hero__projects img {
    height: 60px;
  }
  .projects-hero__h1 {
    line-height: 1;
  }
  .projects-hero__h2 {
    line-height: 0;
  }
}
@media screen and (max-width: 375px) {
  .projects-hero__h1 {
    line-height: 1;
  }
  .projects-hero__h2 {
    line-height: 0;
  }
}
@media screen and (max-width: 340px) {
}
@media (max-width: 960px) {
  .content {
    padding: 0 3rem 0 3rem;
  }
}
</style>
